.navbar {
  background-color: black !important;
}

.navbar-item {
  font-size: 20px;
  font-weight: 600;
  color: rgb(238, 121, 175) !important;
}

.navbar-burger {
  color: rgb(238, 121, 175) !important;
}

#main-nav {
  position: absolute;
  width: 100%;
}